@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans:300,400,500,700);
html {
    width:100%;
    min-width: 850px;
    height: 100%;
    background-color: #1b2f59;
}

body {
    background: black url(../../static/media/bg.fd7a2da2.jpg) no-repeat center center;
    background-size: cover;
    /*font-family: 'Alegreya Sans', sans-serif;*/
    font-family: 'Lato', 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #4C4C4C;
    overflow-x: hidden;
}
a{
    color: #1B2F59;
}
br{
    display: none;
}
a:hover{
    text-decoration:none;
    opacity: 0.9;
}
#layout {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
}
#header {
    font-weight: bold;
}
#footer {
    background-color: #F7F7F7;
    width: 100%;
}
#body {
    min-height: 500px;
}
#seedMouseBox {
    padding: 10%;
    margin-bottom: 15px;
    height: 250px;
    border: 2px solid #666;
    color: #666;
    text-align: center;
    font-size: 1.5em;
}
.seedpoint {
  position: absolute;
  padding: 6px;
  border-radius: 6px;
  background-color: #337ab7;
  z-index: 10;
}
.max-width {
    word-break: break-all;
    max-width: 100%;
}

.zenHeader .container-fluid{
    padding: 0px 30px;
   box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.08), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.zenHeader{
    margin-bottom: 0px;
}
.zenHeader  .navbar-text{
    margin-left: 0px;
    margin-right: 0px;
    font-size: 13px;
}
 .headerLeftOption > a{
     color: red;
}
.zenHeader .nav li a{
    font-weight: 400;
    color: #1B2F59;
}
.zenHeader .nav li a:hover{
    color: #1B2F59;
}
.zenHeader .navbar-brand{
    margin: -20px;
    padding: 0;
    position: relative;
    height: 100px;
}



.navbar-header {
    /*background: #69ffbd none repeat scroll 0 0;*/
    position: relative;
}
.navbar-header::after {
    left: 100%;
    width: 15%;
    /*background: #69ffbd none repeat scroll 0 0;*/
    content: "";
    display: block;
    height: 100%;
    position: absolute;
}
.navbar-header::before, .navbar-header::before {
    /*background: #69ffbd none repeat scroll 0 0;*/
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: 100%;
    top: 0;
    width: 9999px;
}
.zenHeader .nav{
    padding: 6px 0px;
}
/*________________tab section css___________*/

.zenTabsWrap{
    text-align: center;
    padding: 70px 0px;
}
.zenTabsWrap .nav-justified{
    display: inline-block;
    width: auto;
    padding-bottom: 10px;
}
.zenTabs {
    text-align: left;
}
.zenTabs .r3{
    margin: 15px auto auto auto;
}
.zenTabs ul{
    list-style-type: none;
    padding: 0;
    margin: 0px;
    margin-bottom: 10px;
}
.zenTabsWrap .nav-justified > li{
    width: auto;
}
.zenTabsWrap .nav-justified > li a{
    border-radius: 0;
    font-size: 16px;
    padding: 12px 0;
    text-align: center;
    text-transform: capitalize;
    width: 158px;
    outline: none;
    background-color: #1b2f59;
    color: #fff;
    font-weight: 500;
}
.zenTabsWrap .nav-justified > li + li a{
    margin-left: 3px;
}
.zenTabsWrap .nav-pills > li.active > a,
 .zenTabsWrap .nav-pills > li.active > a:focus,
.zenTabsWrap  .nav-pills > li.active > a:hover {
    background-color: #69FFBD;
    color: #1b2f59;
}

.zenTabs > div {
    padding-top: 20px;
}

.zenTabs {
    box-shadow: 0px 0px 10px #CCC;
    background: #ededed;
    padding: 24px 20px 14px 20px;
}

.btn-default {
    background-color: #fff;
    background-image: none;
    background-repeat: repeat-x;
    border: 1px solid #1b2f59;
    border-radius: 0;
    color: #1b2f59;
    padding: 3px 10px;
    text-shadow: 0 0;
}
.btn-default:hover{
    background-color: #1b2f59;
    color: #fff;
    border-color: #1b2f59;
}

hr{
    display: none;
}
.radio-inline {
    margin-right: 30px;
    display: inline-block;
}
.max-width{
    padding-top: 15px;
}
.r2 h1{
    font-size: 32px;
    padding: 0px;
    margin: 0px;
    margin-bottom: 6px;
}
.r2 h3{
    font-size: 23px;
    padding: 0px;
    margin: 0px;
    margin-bottom: 15px;

}

.zentabcode{
    max-width: 200px;
}

#Multisig .btn {
    margin-top: 28px;
}
#Bulk .radio-inline {
    margin-top: 30px;
}
#Bulk .btn {
    margin-top: 27px;
}
#Brain .radio-inline {
    margin-top: 24px;
}
#Brain .btn {
    margin-top: 27px;
}

#Details .btn {
    margin-top: 27px;
}

.multisigsubtitle {
    margin-left: 15px !important;
    margin-top: 15px !important;
}
.multisigsecretcode{
    max-width: 150px
}

/**--------------footer-css---------**/

#footer{
    background-color: #1b2f59;
    padding: 30px 0px;
}
#footer .footerSocialWrap{
    text-align: center;
}
#footer .footerSocial{
    padding: 0px;
    margin: 0 auto;
    list-style-type: none;
    text-align: center;
    display: inline-block;
    padding-bottom:40px;
    margin-bottom: 30px;
    border-bottom: 1px solid #304268;
}


#footer .footerSocial li {
    display: inline-block;
}
#footer .footerSocial li a{
    display: block;
}
#footer .footerCopyright{
    text-align: center;
    color: #fff;
}

#footer .footerCopyright a, #footer .footerCopyright a:hover{
  color: #69FFBD;
  padding: 0px 5px;
  text-decoration: none;
}
#footer .footerCopyright a:hover{

}

.footerSocial li a{
    padding: 0px 10px;
}
.footerSocial li + li a{
    margin-left: 10px;
}
.footerSocial a:hover {
    opacity: 0.6;
}

@media screen {
    .print-only {
        display: none;
    }

    #art-area {
        background-image: url(../../static/media/zen_paper_front.b2f9fe3f.png);
        background-repeat: no-repeat;
        background-size: contain;
        width: 1000px;
        height: 370px;
        position: relative;
        margin: auto;
        overflow: hidden;
        text-align: center;
    }

    #art-area>#addr-QR {
        position: absolute;
        left: 150px;
        top: 130px;
    }
    #art-area>#addr-QR>svg {
        width: 103px;
    }

    #art-area>#wif-QR {
        position: absolute;
        right: 86px;
        top: 124px;
    }
    #art-area>#wif-QR>svg {
        width: 103px;
    }

    #art-area>#addr-str {
        font-size: 8px;
        position: absolute;
        left: 86px;
        top: 275px;
        width: 231px;
        color: black;
    }

    #art-area>#wif-str {
        position: absolute;
        right: 63px;
        top: 248px;
        width: 148px;
        font-size: 7px;
        color: black;
    }
}

@media print {
    body, html {
        border: 1px solid white;
        height: 99%;
        page-break-after: avoid;
        page-break-before: avoid;
    }
    #root {
        page-break-after: avoid;
        page-break-before: avoid;
        max-width: 800px;
        max-height: 800px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .screen-only {
        display: none;
    }
    .r2, .page-break-after {
        page-break-after: always;
    }
    @page {
        size: landscape;
        margin: 0;
    }
    .container{
        width: 100%;
        padding: 0;
    }
    body{
        /*position: relative;*/
    }
    #header, #footer, .nav, hr, .r1, .r3, h2{
        display: none!important;
    }

    .singleTabs {
        width: 100%;
    }

    #layout {
        display: block;
    }

    #art1, #art2 {
        width: 100%;
    }

    .max-width{
        padding: 0;
        margin: 0px auto 20px auto;
        text-align: center
    }
    .zenTabsWrap{
        padding: 0px;
    }
    .zentabcode{
       margin: 0 auto;
    }

    .zenTabs > div {
        padding-top: 0px;
    }

    .multisigsecretcode{
        margin: 0 auto;
    }

    #Bulk{
        margin-top: 20px;
    }

    #layout {
        display: block;
    }

    #art-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #fff !important;
        -webkit-print-color-adjust: exact;
    }

    #art-area>#addr-QR {
        position: absolute;
        top: 63.5%;
        left: 15.1%;
        width: 10.2%;
    }
    #art-area>#addr-QR>svg {
        width: 100%;
    }
    #art-area>#wif-QR {
        position: absolute;
        left: 81.25%;
        top: 62.6%;
        width: 10.2%;
    }
    #art-area>#wif-QR>svg {
        width: 100%;
    }
    #art-area>#addr-str {
        color: black !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
        position: absolute;
        top: 83.4%;
        left: 8.8%;
        width: 22.8%;
        font-size: 6.5px;
    }
    #art-area>#wif-str {
        color: black !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
        position: absolute;
        top: 79.3%;
        left: 78.8%;
        width: 15%;
        font-size: 6.5px;
    }


    .bulk-paper {
        position: relative;
    }

    .bulk-paper>#addr-QR {
        position: absolute;
        display: block;
        left: 76.5%;
        top: 21%;
    }
    .bulk-paper>#addr-QR>svg {
        width: 103px;
    }

    .bulk-paper>#wif-QR {
        position: absolute;
        display: block;
        left: 7.75%;
        top: 21.3%;
    }
    .bulk-paper>#wif-QR>svg {
        width: 103px;
    }

    .bulk-paper>#addr-str {
        position: absolute;
        display: block;
        left: 70%;
        top: 32.5%;
        font-size: 1.1vw;
    }
    .bulk-paper>#addr-str {
        position: absolute;
        display: block;
        left: 70%;
        top: 16.25%;
        font-size: 1.1vw;
        -webkit-transform: rotate(-180deg) !important;
        transform: rotate(-180deg) !important;
    }

    .bulk-paper>#wif-str {
        position: absolute;
        display: block;
        left: 12%;
        top: 30.75%;
        font-size: 0.76vw;
    }
    .bulk-paper>#wif-str2 {
        position: absolute;
        display: block;
        left: 12%;
        top: 18.5%;
        font-size: 0.76vw;
        -webkit-transform: rotate(-180deg) !important;
        transform: rotate(-180deg) !important;
    }



    /*@-moz-document url-prefix() {*/
        /*#art-area>#addr-QR {*/
            /*position: relative;*/
            /*display: block;*/
            /*left: 31%;*/
            /*top: 42%;*/
        /*}*/
        /*#art-area>#addr-QR>svg {*/
            /*width: 12vw !important;*/
            /*height: 12vw !important;*/
        /*}*/

        /*#art-area>#wif-QR {*/
            /*position: relative;*/
            /*display: block;*/
            /*left: -36.5%;*/
            /*top: 21.5%;*/
        /*}*/
        /*#art-area>#wif-QR>svg {*/
            /*width: 11vw !important;*/
            /*height: 11vw !important;*/
        /*}*/

        /*#art-area>#addr-str1 {*/
            /*position: relative;*/
            /*display: block;*/
            /*left: 28%;*/
            /*top: 48.4%;*/
            /*font-size: 1.1vw;*/
        /*}*/
        /*#art-area>#addr-str2 {*/
            /*position: relative;*/
            /*display: block;*/
            /*left: 28%;*/
            /*top: 13.5%;*/
            /*font-size: 1.1vw;*/
            /*-webkit-transform: rotate(-180deg) !important;*/
            /*-moz-transform: rotate(-180deg) !important;*/
            /*-o-transform: rotate(-180deg) !important;*/
            /*-ms-transform: rotate(-180deg) !important;*/
            /*transform: rotate(-180deg) !important;*/
        /*}*/

        /*#art-area>#wif-str1 {*/
            /*position: relative;*/
            /*display: block;*/
            /*left: -27.25%;*/
            /*top: 24.22%;*/
            /*font-size: 0.77vw;*/
        /*}*/
        /*#art-area>#wif-str2 {*/
            /*position: relative;*/
            /*display: block;*/
            /*left: -27.25%;*/
            /*top: -1%;*/
            /*font-size: 0.77vw;*/
            /*-webkit-transform: rotate(-180deg) !important;*/
            /*-moz-transform: rotate(-180deg) !important;*/
            /*-o-transform: rotate(-180deg) !important;*/
            /*-ms-transform: rotate(-180deg) !important;*/
            /*transform: rotate(-180deg) !important;*/
        /*}*/
    /*}*/
}

